:root {
  --primary-color: #00a8e8;
  --secondary-color: #ffffff;
  --background-color: #1c2331;
  --text-color: #e0e0e0;
  --card-background: #2c3e50;
  --input-background: #34495e;
  --button-hover: #0056b3;
  --accent-color: #39ff14;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: var(--background-color);
  color: var(--text-color);
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dark-mode {
  background-color: var(--background-color);
  color: var(--text-color);
}

.light-mode {
  --background-color: #f0f0f0;
  --text-color: #333333;
  --card-background: #ffffff;
  --input-background: #e0e0e0;
}

header {
  background: linear-gradient(135deg, var(--primary-color), #0056b3);
  color: var(--secondary-color);
  text-align: center;
  padding: 20px 0;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

h1, h2 {
  margin: 0;
  font-size: 2.5em;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

h2 {
  margin-top: 8px;
  font-size: 1.8em;
  margin-bottom: 15px;
}

h3 {
  font-weight: normal;
}

.header-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.usage-limit {
  text-align: center;
  margin-top: 10px;
  font-size: 0.9em;
  color: var(--text-color);
}

.text-prompt-section {
  margin-top: 20px;
}

main {
  background-color: var(--card-background);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 30px;
}

.input-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.modern-input {
  flex: 1;
  min-width: 100px;
  padding: 12px 15px;
  border: 1px solid var(--primary-color);
  border-radius: 25px;
  font-size: 1em;
  background-color: var(--input-background);
  color: var(--text-color);
  appearance: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modern-input[type="text"] {
  padding-right: 15px;
}

.modern-input[name="ano"] {
  padding-right: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.modern-input:focus, .modern-textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--accent-color);
}

.modern-input:hover, .modern-textarea:hover {
  transform: translateY(-2px);
}

.modern-textarea {
  width: 97%;
  resize: vertical;
  border-radius: 15px;
  padding: 20px 15px;
  border: 1px solid var(--primary-color);
  font-size: 1em;
  background-color: var(--input-background);
  color: var(--text-color);
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.obd-input {
  margin-top: 10px;
}

.mode-selector {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.mode-selector button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  background: linear-gradient(135deg, var(--primary-color), #0056b3);
  color: var(--secondary-color);
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mode-selector button.active {
  background: linear-gradient(135deg, #0056b3, var(--primary-color));
}

.mode-selector button:hover {
  background: linear-gradient(135deg, #0056b3, var(--primary-color));
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.upload-section {
  margin-top: 18px;
}

.dropzone {
  border: 2px dashed var(--accent-color);
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgba(57, 255, 20, 0.05);
}

.dropzone:hover, .dropzone.active, .dropzone.has-image {
  border-color: var(--accent-color);
  background-color: rgba(57, 255, 20, 0.1);
}

.dropzone p {
  margin: 0;
  font-size: 1.2em;
  color: var(--text-color);
}

.image-preview {
  position: relative;
  margin-top: 20px;
  text-align: center;
}

.image-preview img {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.remove-image {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.remove-image:hover {
  background-color: var(--button-hover);
}

.analyze-btn {
  background: linear-gradient(135deg, var(--primary-color), #0056b3);
  color: var(--secondary-color);
  border: none;
  padding: 12px 24px;
  font-size: 1.1em;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 30px auto 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.analyze-btn:hover:not(:disabled) {
  background: linear-gradient(135deg, #0056b3, var(--primary-color));
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.analyze-btn:disabled {
  background-color: var(--card-background);
  border-color: var(--card-background);
  color: var(--text-color);
  opacity: 0.5;
  cursor: not-allowed;
}

.loading {
  text-align: center;
  font-size: 1.2em;
  color: var(--text-color);
  margin: 20px 0;
}

.loading-animation {
  width: 50px;
  height: 50px;
  border: 3px solid var(--primary-color);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.h1-news {
  display: flex;
  justify-content: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.analysis-results, .error-message {
  background-color: var(--input-background);
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
}

.analysis-results p, .error-message p {
  margin: 0 0 15px 0;
  line-height: 1.6;
}

.error-message {
  background-color: rgba(255, 77, 77, 0.2);
  color: var(--primary-color);
}

footer {
  text-align: center;
  margin-top: 30px;
  color: var(--text-color);
  font-size: 0.9em;
}

.navigation {
  background: linear-gradient(135deg, var(--primary-color), #0056b3);
  padding: 10px 0;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  justify-content: start;
  flex-grow: 1;
}

.nav-link {
  color: var(--secondary-color);
  text-decoration: none;
  margin: 0 15px;
  font-weight: bold;
  transition: color 0.3s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.nav-link:hover {
  color: var(--accent-color);
}

.nav-controls {
  display: flex;
  align-items: center;
}

.mode-toggle {
  background-color: transparent;
  border: none;
  color: var(--secondary-color);
  cursor: pointer;
  font-size: 1.5em;
  padding: 5px;
  margin-right: 5px;
}

.language-selector {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--input-background);
  color: var(--secondary-color);
  border: none;
  font-size: 0.9em;
}

.about-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.about-page header {
  text-align: center;
  margin-bottom: 30px;
}

.about-page h1 {
  color: white;
  text-align: center;
}

.about-page h2 {
  color: var(--primary-color);
  margin-top: 30px;
}

.about-page p {
  line-height: 1.6;
}

.news-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.about-page ul {
  padding-left: 20px;
}

.about-page li {
  margin-bottom: 10px;
}

.about-page .intro {
  font-style: italic;
  margin-bottom: 30px;
}

.about-page footer {
  margin-top: 50px;
  text-align: center;
  color: var(--text-color);
}

.cta-button {
  display: block;
  width: max-content;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--button-hover);
}

.blog-content {
  background-color: var(--card-background);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.blog-content h2 {
  color: var(--primary-color);
}

.blog-content ul {
  padding-left: 20px;
}

.blog-content li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
 
 
.h1, .h2 {
  margin: 0;
  font-size: 2.5em;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  text-align: center;
}
 
  .h1-news {
    display: flex;
    text-align: center;
    margin: 0;
    padding: 0px;
  }

  .mode-toggle {
    background-color: transparent;
    border: none;
    color: var(--secondary-color);
    cursor: pointer;
    font-size: 1.5em;
    padding: 5px;
    margin-right: 235px;
  }

  .nav-container {
    flex-direction: column;
  }

  .nav-links {
    margin-bottom: 10px;
  }

  .nav-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .language-selector {
    width: 97%;
  }

  .about-page, .news-page, .App {
    padding: 10px;
  }

  .input-group {
    width: 90%;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    max-width: 100%;
  }

  .modern-input {
    width: 100%;
    min-width: 0;
  }

  .modern-input[name="ano"] {
    grid-column: 1 / -1;
    width: calc(50% - 5px);
    margin-left: auto;
    margin-right: auto;
  }

  .mode-selector {
    flex-direction: column;
  }

  .mode-selector button {
    width: 99%;
  }

  .modern-textarea {
    
    max-width: 89%;
  }

  .obd-input {
    max-width: 90%;
  }

  .cta-button {
    width: 100%;
    text-align: center;
  }
}